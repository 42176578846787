export const Ingredients = {
  liquors: [
    "151 Proof Rum",
    "Añejo Rum",
    "Absinthe",
    "Applejack",
    "Apple Brandy",
    "Apricot Brandy",
    "Aquavit",
    "Blackberry Brandy",
    "Blackstrap Rum",
    "Blended Scotch",
    "Blended Whiskey",
    "Bourbon",
    "Brandy",
    "Cachaca",
    "Cherry Brandy",
    "Coconut Rum",
    "Coffee Brandy",
    "Cognac",
    "Corona",
    "Cranberry Vodka",
    "Dark Rum",
    "Everclear",
    "Firewater",
    "Gin",
    "Gold Rum",
    "Gold Tequila",
    "Irish Whiskey",
    "Lemon Rum",
    "Lemon Vodka",
    "Light Rum",
    "Lime Vodka",
    "Malibu Rum",
    "Mezcal",
    "Peach Vodka",
    "Peach Brandy",
    "Pisco",
    "Raspberry Vodka",
    "Rum",
    "Rye Whiskey",
    "Sake",
    "Scotch",
    "Shochu",
    "Sloe gin",
    "Soju",
    "Spiced rum",
    "Tequila",
    "Vanilla Vodka",
    "Vodka",
    "Whiskey",
    "White Rum",
  ],
  beers: [
    "Ale",
    "Beer",
    "Guinness Stout",
    "Lager",
    "Zima"
  ],
  wines: [
    "Champagne",  
    "Dry Vermouth",
    "Port",
    "Prosecco",
    "Red wine",
    "Rose",
    "Rosso Vermouth",
    "Ruby Port",
    "Sherry",
    "Sweet Vermouth",
    "Vermouth",
    "White Wine",
    "Wine"
  ],
  liqueurs: [
    "Advocaat",
    "Amaretto",
    "Amaro Montenegro",
    "Anisette",
    "Aperol",
    "Apfelkorn",
    "Apple Schnapps",
    "Baileys Irish Cream",
    "Banana Liqueur",
    "Benedictine",
    "Black Sambuca",
    "Blueberry Schnapps",
    "Blue Curacao",
    "Butterscotch Schnapps",
    "Campari",
    "Chambord Raspberry Liqueur",
    "Cherry Heering",
    "Cherry Liqueur",
    "Chocolate Liqueur",
    "Coconut Liqueur",
    "Cointreau",
    "Coffee Liqueur",
    "Creme de Banane",
    "Creme de Cacao",
    "Creme de Cassis",
    "Creme de Menthe",
    "Creme de Mure",
    "Curacao",
    "Dark Creme de Cacao",
    "Drambuie",
    "Dry Curacao",
    "Dubonnet Rouge",
    "Erin Cream",
    "Falernum",
    "Frangelico",
    "Galliano",
    "Grand Marnier",
    "Godiva Liqueur",
    "Goldschlager",
    "Green Chartreuse",
    "Green Creme de Menthe",
    "Hot Damn",
    "Hpnotiq",
    "Irish Cream",
    "Jägermeister",
    "Kahlua",
    "Kirschwasser",
    "Kiwi Liqueur",
    "Kummel",
    "Lillet Blanc",
    "Lillet",
    "Limoncello",
    "Maraschino Liqueur",
    "Maui",
    "Melon Liqueur",
    "Midori Melon Liqueur",
    "Orange Curacao",
    "Ouzo",
    "Passoa",
    "Peach Schnapps",
    "Peachtree Schnapps",
    "Peppermint Schnapps",
    "Pernod",
    "Pisang Ambon",
    "Raspberry Liqueur",
    "Ricard",
    "Rumple Minze",
    "Sambuca",
    "Southern Comfort",
    "St. Germain",
    "Strawberry Liqueur",
    "Strawberry Schnapps",
    "Tia Maria",
    "Triple Sec",
    "White Creme de Menthe",
    "Yellow Chartreuse",
    "Yukon Jack",
  ],
  mixers: [
    "7-Up",
    "Agave Syrup",
    "Apple Cider",
    "Apple Juice",
    "Apricot Nectar",
    "Blackcurrant Cordial",
    "Carbonated Soft Drink",
    "Carbonated Water",
    "Cherry Grenadine",
    "Cherry Juice",
    "Chocolate milk",
    "Cider",
    "Club Soda",
    "Coca-Cola",
    "Coconut Milk",
    "Coffee",
    "Condensed Milk",
    "Cranberry Juice",
    "Cream of Coconut",
    "Cream",
    "Daiquiri Mix",
    "Dr. Pepper",
    "Elderflower Cordial",
    "Espresso",
    "Fresca",
    "Fruit Juice",
    "Fruit Punch",
    "Ginger Beer",
    "Ginger Ale",
    "Grape Soda",
    "Grape Juice",
    "Grapefruit Juice",
    "Grenadine",
    "Guava Juice",
    "Half-and-Half",
    "Heavy Cream",
    "Hot Chocolate",
    "Iced Tea",
    "Kool-Aid",
    "Lemon Juice",
    "Lemon-Lime Soda",
    "Lemonade",
    "Lime Juice",
    "Lime Juice Cordial",
    "Limeade",
    "Milk",
    "Mountain Dew",
    "Olive Brine",
    "Orange Juice",
    "Passion Fruit Juice",
    "Peach Nectar",
    "Pepsi Cola",
    "Pina Colada Mix",
    "Pineapple Juice",
    "Pink Lemonade",
    "Pomegranate Juice",
    "Root Beer",
    "Roses Sweetened Lime Juice",
    "Sarsaparilla",
    "Schweppes Russchian",
    "Soda Water",
    "Sour Mix",
    "Sprite",
    "Surge",
    "Sweet and Sour Mix",
    "Tea",
    "Tomato Juice",
    "Tonic Water",
    "Tropicana",
  ],
  garnishes: [
    "Lemon Peel",
    "Lemon",
    "Lime Peel",
    "Lime",
    "Nutmeg",
    "Orange Peel",
    "Orange Spiral",
    "Orange",
  ],
  other: [
    "Allspice",
    "Almond Flavoring",
    "Angostura Bitters",
    "Anis",
    "Apple",
    "Banana",
    "Basil",
    "Berries",
    "Bitter Lemon",
    "Bitters",
    "Black pepper",
    "Blackberries",
    "Blackcurrant Squash",
    "Blood Orange",
    "Blueberries",
    "Brown sugar",
    "Butter",
    "Candy",
    "Cantaloupe",
    "Caramel Coloring",
    "Caramel Sauce",
    "Cardamom",
    "Carrot",
    "Cayenne Pepper",
    "Celery Salt",
    "Cherries",
    "Cherry",
    "Chocolate Sauce",
    "Chocolate Ice-Cream",
    "Chocolate syrup",
    "Chocolate",
    "Cinnamon",
    "Cloves",
    "Cocoa Powder",
    "Coconut Syrup",
    "Coriander",
    "Corn Syrup",
    "Cornstarch",
    "Cranberries",
    "Cucumber",
    "Cumin Seed",
    "Demerara Sugar",
    "Egg White",
    "Egg Yolk",
    "Egg",
    "Fennel seeds",
    "Figs",
    "Food Coloring",
    "Fruit",
    "Ginger Syrup",
    "Ginger",
    "Glycerine",
    "Grapes",
    "Honey Syrup",
    "Honey",
    "Hot Sauce",
    "Ice",
    "Jello",
    "Kiwi",
    "Lavender",
    "Mango",
    "Maple syrup",
    "Maraschino Cherry",
    "Marjoram leaves",
    "Marshmallows",
    "Mini-Snickers Bars",
    "Mint Syrup",
    "Mint",
    "Olive",
    "Orange Bitters",
    "Oreo Cookie",
    "Orgeat Syrup",
    "Papaya",
    "Passion Fruit Syrup",
    "Peach Bitters",
    "Pepper",
    "Peppermint Extract",
    "Peychaud Bitters",
    "Pineapple Syrup",
    "Pineapple",
    "Powdered Sugar",
    "Raisins",
    "Raspberry Syrup",
    "Red Chili Flakes",
    "Rosemary Syrup",
    "Rosemary",
    "Salt",
    "Salted Chocolate",
    "Sherbet",
    "Simple Syrup",
    "Sirup of Roses",
    "Soy Sauce",
    "Strawberries",
    "Sugar Syrup",
    "Sugar",
    "Tabasco Sauce",
    "Thyme",
    "Vanilla Ice-Cream",
    "Vanilla Extract",
    "Vanilla Syrup",
    "Vanilla",
    "Watermelon",
    "Whipped Cream",
    "Whipping Cream",
    "Worcestershire Sauce",
    "Wormwood",
    "Yoghurt",
  ]
};